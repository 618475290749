import React from "react";

import styles from "./instruction.module.css";

function Instruction({ children }) {
  return (
    <div className={styles.instruction}>
      <i>{children}</i>
    </div>
  );
}

export default Instruction;
