import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Icon, Menu, Segment, Card, Image, Table } from "semantic-ui-react";
import styles from "./css/page_1_0_0.module.css";

function Page_1_0_0({ playAudio }) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeItem, setActiveItem] = useState("1");
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_0_0/audio_0.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  const handleItemClick = name => setActiveItem(name);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.leftHolder}>
          <Card>
            <Image
              src="/assets/images/page_1_0_0/drawing_board.png"
              wrapped
              ui={false}
            />
            <Card.Content>
              <Card.Header>Drawing Board</Card.Header>
              {/* <Card.Meta>
                <span className="date">Joined in 2015</span>
              </Card.Meta> */}
              <Card.Description>
                Drawing board is a rectangular board made up of hard, durable
                seasoned wood. The drawing paper will always be placed over the
                drawing board which serves as a flat surfae to draw properly.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Instruction>Click on tab to know more.</Instruction>
            </Card.Content>
          </Card>
        </div>
        <div className={styles.rightHolder}>
          <Menu pointing className={styles.menuHolder}>
            <Menu.Item
              name="1"
              active={activeItem === "1"}
              onClick={() => handleItemClick("1")}
            >
              Parts
            </Menu.Item>

            <Menu.Item
              name="2"
              active={activeItem === "2"}
              onClick={() => handleItemClick("2")}
            >
              Usage
            </Menu.Item>

            <Menu.Item
              name="3"
              active={activeItem === "3"}
              onClick={() => handleItemClick("3")}
            >
              Various types and designations
            </Menu.Item>
          </Menu>
          <Segment className={styles.sectionHolder}>
            {activeItem === "1" && (
              <React.Fragment>
                <div className={styles.sectionTopHolder}>
                  <img
                    src="/assets/images/page_1_0_0/db_parts.png"
                    className={styles.image}
                  />
                </div>
                <div className={styles.sectionBottomHolder}>
                  <p>The drawing board is made up of following parts:</p>
                  <ul>
                    <li>- Batten</li>
                    <li>- Working surface</li>
                    <li>- Working edge</li>
                  </ul>
                </div>
              </React.Fragment>
            )}
            {activeItem === "2" && (
              <React.Fragment>
                <div className={styles.sectionTopHolder}>
                  <img
                    src="/assets/images/page_1_0_0/db_usage.gif"
                    className={styles.image}
                  />
                </div>
                <div className={styles.sectionBottomHolder}>
                  <p>
                    The drawing board is used to:
                    <ul>
                      <li>- Create flat surface</li>
                      <li>- Place the drawing paper</li>
                      <li>- Guide the T-square</li>
                    </ul>
                  </p>
                </div>
              </React.Fragment>
            )}
            {activeItem === "3" && (
              <React.Fragment>
                <div className={styles.sectionSingleHolder}>
                  <p>
                    The table illustrate the different sizes of the drawing
                    boards used.
                  </p>
                  <Table celled className={styles.table}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Designation</Table.HeaderCell>
                        <Table.HeaderCell>Size(in mm)</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>D0</Table.Cell>
                        <Table.Cell>1500 X 1000</Table.Cell>
                      </Table.Row>
                      <Table.Row warning>
                        <Table.Cell>D1</Table.Cell>
                        <Table.Cell>1000 X 700</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>D2</Table.Cell>
                        <Table.Cell>700 X 500</Table.Cell>
                      </Table.Row>
                      <Table.Row warning>
                        <Table.Cell>D3</Table.Cell>
                        <Table.Cell>500 X 350</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </React.Fragment>
            )}
          </Segment>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_1_0_0;
