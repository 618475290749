import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Card, Image, Button, Icon } from "semantic-ui-react";
import Video from "./video";
import styles from "./css/page_1_0_4.module.css";

function Page_1_0_4({ playAudio }) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [end, setEnd] = useState(true);
  const [open, setOpen] = useState(false);
  const [externalControl, setExternalControl] = useState("stop");
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_0_4/audio_0.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  const togglePlay = (e, titleProps) => {
    if (end) {
      setEnd(false);
    } else {
      setEnd(true);
    }
  };
  useEffect(() => {
    if (!end) {
      setExternalControl("play");
    } else {
      setExternalControl("stop");
    }
  }, [end]);

  const [isHorizontal, setIsHorizontal] = useState(true);
  const [rotateFn, setRotateFn] = useState(
    isHorizontal ? "rotateY" : "rotateX"
  );
  const [radius, setRadius] = useState(null);
  const [theta, setTheta] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  var carousel = useRef();
  var carousel_1 = useRef();
  var carousel_2 = useRef();
  var carousel_3 = useRef();
  var carousel_4 = useRef();
  var carousel_5 = useRef();
  const rotateCarousel = (theta, radius) => {
    var angle = theta * selectedIndex * -1;
    carousel.current.style.transform =
      "translateZ(" + -radius + "px) " + rotateFn + "(" + angle + "deg)";
  };

  const onPrevButton = () => {
    setSelectedIndex(selectedIndex - 1);
  };
  const onNextButton = () => {
    setSelectedIndex(selectedIndex + 1);
  };
  useEffect(() => {
    rotateCarousel(theta, radius);
  }, [selectedIndex]);

  const changeCarousel = () => {
    const cellCount = 5;
    const theta = 360 / cellCount;
    /* var cellSize = isHorizontal ? cellWidth : cellHeight; */
    const cellSize = 505;
    var cells = [carousel_1, carousel_2, carousel_3, carousel_4, carousel_5];
    const radius = Math.round(cellSize / 2 / Math.tan(Math.PI / cellCount));
    for (var i = 0; i < cells.length; i++) {
      var cell = cells[i].current;
      console.log(cell);
      if (i < cellCount) {
        // visible cell
        cell.style.opacity = 1;
        var cellAngle = theta * i;
        cell.style.transform =
          rotateFn + "(" + cellAngle + "deg) translateZ(" + radius + "px)";
      } else {
        // hidden cell
        cell.style.opacity = 0;
        cell.style.transform = "none";
      }
    }
    setTheta(theta);
    setRadius(radius);
    rotateCarousel(theta, radius);
  };

  useEffect(() => {
    changeCarousel();
  }, []);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.leftHolder}>
          <Card>
            <div className={styles.imageHolder}>
              <Image
                src="/assets/images/page_1_0_4/instrument_box.png"
                wrapped
                ui={false}
                className={styles.image}
              />
            </div>
            <Card.Content>
              <Card.Header>Drawing Instrument box</Card.Header>
              {/* <Card.Meta>
                <span className="date">Joined in 2015</span>
              </Card.Meta> */}
              <Card.Description>
                The instruments that are used to do drawings are known as
                drawing instruments. Normally, drawing instrument box contain
                the following tools:
                <ul>
                  <li>Compass (large and small bow)</li>
                  <li>Divider (large and small bow)</li>
                  <li>Lengthening bar</li>
                  <li>Pencil leds</li>
                  <li>screw driver</li>
                </ul>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Instruction>Click on next/previous to know more.</Instruction>
            </Card.Content>
          </Card>
        </div>
        <div className={styles.rightHolder}>
          <div className={styles.scene}>
            <div className={styles.carousel} ref={carousel}>
              <div className={styles.carousel__cell} ref={carousel_1}>
                <div className={styles.contentHolder}>
                  <Image
                    src="/assets/images/page_1_0_4/instrument_1.png"
                    wrapped
                    ui={false}
                    className={styles.image}
                  />
                  <div className={styles.contentText}>
                    <b>Usage of Compass (large and small bow):</b>
                    <br />
                    Compass is a tool used to draw circle and arc. We have 2
                    types of compass:
                    <br /> - Large bow
                    <br /> - Small bow
                    <br />
                    <br /> Large bow compass is used to draw bigger circle and
                    arc of bigger radius. Small bow compass is used to draw
                    smaller circle and arc of smaller radius.
                  </div>
                </div>
              </div>
              <div className={styles.carousel__cell} ref={carousel_2}>
                <div className={styles.contentHolder}>
                  <Image
                    src="/assets/images/page_1_0_4/instrument_2.png"
                    wrapped
                    ui={false}
                    className={styles.image}
                  />
                  <div className={styles.contentText}>
                    <b>Usage of Divider (large and small bow):</b>
                    <br />
                    Divider is a tool used to measure and mark distance from
                    scale to a drawing or from a drawing to another drawing and
                    also to divide part of line or curve.
                  </div>
                </div>
              </div>
              <div className={styles.carousel__cell} ref={carousel_3}>
                <div className={styles.contentHolder}>
                  <Image
                    src="/assets/images/page_1_0_4/instrument_3.png"
                    wrapped
                    ui={false}
                    className={styles.image}
                  />
                  <div className={styles.contentText}>
                    <b>Usage of lengthening bar:</b>
                    <br />
                    Lengthening bar is a tool used to draw even more bigger
                    circle and arc that are not possible with compass.
                  </div>
                </div>
              </div>
              <div className={styles.carousel__cell} ref={carousel_4}>
                <div className={styles.contentHolder}>
                  <Image
                    src="/assets/images/page_1_0_4/instrument_4.png"
                    wrapped
                    ui={false}
                    className={styles.image}
                  />
                  <div className={styles.contentText}>
                    <b>Usage of box with pencil leads:</b>
                    <br />
                    Pencil leads are used with compass to draw drawings. The one
                    leg of the compass will have this pencil leads which have to
                    be properly alinged with the another leg of the compass
                    having the needle. The needle have to be 1mm longer than the
                    lead.
                  </div>
                </div>
              </div>
              <div className={styles.carousel__cell} ref={carousel_5}>
                <div className={styles.contentHolder}>
                  <Image
                    src="/assets/images/page_1_0_4/instrument_5.png"
                    wrapped
                    ui={false}
                    className={styles.image}
                  />
                  <div className={styles.contentText}>
                    <b>Usage of screw driver:</b>
                    <br />
                    Screw driver is a tool used to tighten the tools available
                    in the instrument box.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonHolder}>
            <Button onClick={onPrevButton}>Previous</Button>
            <Button onClick={onNextButton}>Next</Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_1_0_4;
