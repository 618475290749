import React, { useState, useEffect, useRef } from "react";
import { IsDesktopOrLaptop } from "../compoments/DeviceCheck";
import useResize from "use-resize";
import Tree from "../compoments/Tree/Tree";
import styles from "./css/main.module.css";
import Video from "./video";
import Navigation from "../compoments/Navigation/Navigation";
import Breadcrumb from "../compoments/Breadcrumb/Breadcrumb";
import Page from "./page";
import Page_0 from "./page_0";
import Page_1 from "./page_1";
import Page_1_0 from "./page_1_0";
import Page_1_2 from "./page_1_2";
import Page_1_1 from "./page_1_1";
import Page_1_3 from "./page_1_3";
import Page_1_4 from "./page_1_4";
import Page_1_0_0 from "./page_1_0_0";
import Page_1_0_1 from "./page_1_0_1";
import Page_1_0_2 from "./page_1_0_2";
import Page_1_0_3 from "./page_1_0_3";
import Page_1_0_4 from "./page_1_0_4";
import Page_1_0_5 from "./page_1_0_5";
import Page_2_3_1_0 from "./page_2_3_1_0";
import Page_2_3_1_1 from "./page_2_3_1_1";

/* import {
  useRCShellContext,
  changeTextColor
} from "../store/contexts/RCShellContext"; */

function Main() {
  //const { data, dispatch } = useRCShellContext();
  const audioPath = [
    {
      id: 1,
      path: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-10.mp3"
    },
    {
      id: 1,
      path: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
    }
  ];
  const [audioToPlay, setAudioToPlay] = useState(null);
  const [tocLoadKey, setTocLoadKey] = useState(null);

  const changeAudio = audio => {
    setAudioToPlay(audio);
  };
  const clickAndChange = key => {
    const obj = getObject(treeDataArray, "key", key);
    setTocLoadKey(obj.key);
    setShowPage(obj.file);
  };
  const treeDataArray = [
    {
      key: "0",
      label: "Introduction",
      icon: "fa fa-folder",
      title: "Introduction",
      expand: true,
      file: <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
    },
    {
      key: "1",
      label: "Concepts and Conventions",
      icon: "fa fa-desktop",
      title: "Concepts and Conventions",
      file: (
        <Page_1 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: [
        {
          key: "1_0",
          label: "Drawing Instruments",
          icon: "fa fa-desktop",
          title: "Drawing Instruments",
          file: (
            <Page_1_0
              playAudio={changeAudio}
              onClickAndChange={clickAndChange}
            />
          ),
          expand: false,
          children: [
            {
              key: "1_0_0",
              label: "Drawing Board",
              icon: "fa fa-desktop",
              title: "Drawing Board",
              file: (
                <Page_1_0_0
                  playAudio={changeAudio}
                  onClickAndChange={clickAndChange}
                />
              ),
              expand: false
            },
            {
              key: "1_0_1",
              label: "Mini Drafter",
              icon: "fa fa-desktop",
              title: "Mini Drafter",
              file: (
                <Page_1_0_1
                  playAudio={changeAudio}
                  onClickAndChange={clickAndChange}
                />
              ),
              expand: false
            },
            {
              key: "1_0_2",
              label: "Drawing Sheet",
              icon: "fa fa-desktop",
              title: "Drawing Sheet",
              file: (
                <Page_1_0_2
                  playAudio={changeAudio}
                  onClickAndChange={clickAndChange}
                />
              ),
              expand: false
            },
            {
              key: "1_0_3",
              label: "T - Square",
              icon: "fa fa-desktop",
              title: "T - Square",
              file: (
                <Page_1_0_3
                  playAudio={changeAudio}
                  onClickAndChange={clickAndChange}
                />
              ),
              expand: false
            },
            {
              key: "1_0_4",
              label: "Drawing instrument box",
              icon: "fa fa-desktop",
              title: "Drawing instrument box",
              file: (
                <Page_1_0_4
                  playAudio={changeAudio}
                  onClickAndChange={clickAndChange}
                />
              ),
              expand: false
            },
            {
              key: "1_0_5",
              label: "Drawing pencils",
              icon: "fa fa-desktop",
              title: "Drawing pencils",
              file: (
                <Page_1_0_5
                  playAudio={changeAudio}
                  onClickAndChange={clickAndChange}
                />
              ),
              expand: false
            }
          ]
        },
        {
          key: "1_1",
          label: "Lettering",
          icon: "fa fa-desktop",
          title: "Lettering",
          file: (
            <Page_1_1
              playAudio={changeAudio}
              onClickAndChange={clickAndChange}
            />
          ),
          expand: false
        },
        {
          key: "1_2",
          label: "Types of lines",
          icon: "fa fa-desktop",
          title: "Types of lines",
          file: (
            <Page_1_2
              playAudio={changeAudio}
              onClickAndChange={clickAndChange}
            />
          ),
          expand: false
        },
        {
          key: "1_3",
          label: "Dimensioning",
          icon: "fa fa-desktop",
          title: "Dimensioning",
          file: (
            <Page_1_3
              playAudio={changeAudio}
              onClickAndChange={clickAndChange}
            />
          ),
          expand: false
        },
        {
          key: "1_4",
          label: "Geometrical Constructions",
          icon: "fa fa-desktop",
          title: "Geometrical Constructions",
          file: (
            <Page_1_4
              playAudio={changeAudio}
              onClickAndChange={clickAndChange}
            />
          ),
          expand: false
        }
      ]
    },
    {
      key: "2",
      label: "Plane Curves and Free Hand Sketching",
      icon: "fa fa-desktop",
      title: "Plane Curves and Free Hand Sketching",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: [
        {
          key: "2_0",
          label: "Conics",
          icon: "fa fa-desktop",
          title: "Conics",
          file: (
            <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
          ),
          expand: false,
          children: []
        },
        {
          key: "2_1",
          label: "Cycloidal Curve",
          icon: "fa fa-desktop",
          title: "Cycloidal Curve",
          file: (
            <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
          ),
          expand: false,
          children: []
        },
        {
          key: "2_2",
          label: "Involutes",
          icon: "fa fa-desktop",
          title: "Involutes",
          file: (
            <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
          ),
          expand: false,
          children: []
        },
        {
          key: "2_3",
          label: "Free Hand Sketching",
          icon: "fa fa-desktop",
          title: "Free Hand Sketching",
          file: (
            <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
          ),
          expand: false,
          children: [
            {
              key: "2_3_0",
              label: "How to Sketch?",
              icon: "fa fa-desktop",
              title: "How to Sketch?",
              file: (
                <Page_0
                  playAudio={changeAudio}
                  onClickAndChange={clickAndChange}
                />
              ),
              expand: false,
              children: []
            },
            {
              key: "2_3_1",
              label: "Solved Problems",
              icon: "fa fa-desktop",
              title: "Solved Problems",
              file: (
                <Page_0
                  playAudio={changeAudio}
                  onClickAndChange={clickAndChange}
                />
              ),
              expand: false,
              children: [
                {
                  key: "2_3_1_0",
                  label: "Problem 1",
                  icon: "fa fa-desktop",
                  title: "Problem 1",
                  file: (
                    <Page_2_3_1_0
                      playAudio={changeAudio}
                      onClickAndChange={clickAndChange}
                    />
                  ),
                  expand: false
                },
                {
                  key: "2_3_1_1",
                  label: "Problem 5",
                  icon: "fa fa-desktop",
                  title: "Problem 5",
                  file: (
                    <Page_2_3_1_1
                      playAudio={changeAudio}
                      onClickAndChange={clickAndChange}
                    />
                  ),
                  swf: "EG_U1_P4_T2_S05_00.swf",
                  expand: false
                },
                {
                  key: "2_3_1_2",
                  label: "Problem 6",
                  icon: "fa fa-desktop",
                  title: "Problem 6",
                  file: (
                    <Page_0
                      playAudio={changeAudio}
                      onClickAndChange={clickAndChange}
                    />
                  ),
                  expand: false
                }
              ]
            }
          ]
        }
      ]
    },
    {
      key: "3",
      label: "Projection of Object",
      icon: "fa fa-desktop",
      title: "Projection of Object",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: []
    },
    {
      key: "4",
      label: "Projection of Points",
      icon: "fa fa-desktop",
      title: "Projection of Points",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: []
    },
    {
      key: "5",
      label: "Projection of Lines",
      icon: "fa fa-desktop",
      title: "Projection of Lines",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: []
    },
    {
      key: "6",
      label: "Projection of Planes",
      icon: "fa fa-desktop",
      title: "Projection of Planes",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: []
    },
    {
      key: "7",
      label: "Projection of Solids",
      icon: "fa fa-desktop",
      title: "Projection of Solids",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: []
    },
    {
      key: "8",
      label: "Section of Solids",
      icon: "fa fa-desktop",
      title: "Section of Solids",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: []
    },
    {
      key: "9",
      label: "Development of Surfaces",
      icon: "fa fa-desktop",
      title: "Development of Surfaces",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: []
    },
    {
      key: "10",
      label: "Isometric Projection",
      icon: "fa fa-desktop",
      title: "Isometric Projection",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: []
    },
    {
      key: "11",
      label: "Perspective Projection",
      icon: "fa fa-desktop",
      title: "Perspective Projection",
      file: (
        <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />
      ),
      expand: false,
      children: []
    }
  ];

  const findParentExpand = (branch, targetKey) => {
    if (branch.key === targetKey) {
      return true;
    } else if (branch.children && branch.children.length > 0) {
      let expand = expandTargetParents(branch.children, targetKey);
      if (expand === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const expandTargetParents = (treeData, targetKey) => {
    if (treeData && treeData.length > 0) {
      for (let i = 0; i < treeData.length; i++) {
        let expand = findParentExpand(treeData[i], targetKey);
        if (expand) {
          treeData[i].expand = expand;
          return expand;
          break;
        }
      }
    }
  };

  const size = useResize();
  const isPC = IsDesktopOrLaptop();
  const [toggleToC, setToggleToC] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [fitOn, setFitOn] = useState("h");
  const [treeData, setTreeData] = useState(treeDataArray);
  const [showPage, setShowPage] = useState(null);
  const [breadCrumbArray, setBreadCrumbArray] = useState([]);

  useEffect(() => {
    setDimensions({
      width: isPC ? window.innerWidth : window.screen.width,
      height: isPC ? window.innerHeight : window.screen.height
    });
  }, [isPC, size, toggleToC]);
  /*   useEffect(() => {
    console.log(expandTargetParents(treeData, "1-1"));
  }, [toggleToC]);
  useEffect(() => {
    console.log(treeData);
  }, [treeData]); */

  const imageOnLoad = ({ target: img }) => {
    img.offsetHeight > img.offsetWidth ? setFitOn("h") : setFitOn("w");
  };

  const getObject = (array, key, value) => {
    var o;
    array.some(function iter(a) {
      if (a[key] === value) {
        o = a;
        return true;
      }
      return Array.isArray(a.children) && a.children.some(iter);
    });
    return o;
  };

  const onPageChange = (file, key) => {
    const keyArray = key.split("_");
    const bcArray = [];
    let keyList = "";
    for (let i = 0; i < keyArray.length; i++) {
      keyList = i === 0 ? keyList + keyArray[i] : keyList + "_" + keyArray[i];
      const obj = getObject(treeDataArray, "key", keyList);
      bcArray.push({ key: obj.key, content: obj.title, file: obj.file });
    }
    setBreadCrumbArray([...bcArray]);
    setTocLoadKey(key);
    setShowPage(file);
  };

  const launchFirstPage = () => {
    setToggleToC(true);
    //setShowPage(<Page_0 playAudio={changeAudio} />);
    onPageChange(
      <Page_0 playAudio={changeAudio} onClickAndChange={clickAndChange} />,
      "0"
    );
  };
  const launchPage = (key, data) => {
    findPageWithKey(data, key, null);
  };
  const loadpage = showpage => {
    let Component = showpage;
    return <Component />;
  };
  const findPageWithKey = (tree, key, foundFile) => {
    let keyID = null;
    tree.map(node => {
      //console.log(node.key, key);
      if (node.key === key) foundFile = node.file;
      if (node.key === key) keyID = node.key;
      if (node.key === key) node.expand = true;
      if (node.children && node.children.length > 0) {
        findPageWithKey(node.children, key, null);
      }
    });
    foundFile && onPageChange(foundFile, keyID);
    setTreeData(tree);
  };
  //console.log("MAIN");
  return (
    <React.Fragment>
      <div
        className={styles.container}
        style={{
          height: dimensions.height + "px",
          width: dimensions.width + "px"
        }}
      >
        <div
          className={styles.tocHolder}
          style={{
            width: toggleToC ? "25%" : 0
          }}
        >
          <div className={styles.logoBg}>
            <img src={"./assets/cover_logo.png"} />
          </div>
          <div className={styles.treeHolder}>
            {toggleToC && <Tree data={treeData} changePage={onPageChange} />}
          </div>
        </div>
        <div
          className={styles.stage}
          style={{ width: toggleToC ? "75%" : "100%" }}
          id="stageContent"
        >
          {showPage && (
            <div className={styles.breadcrumbHolder}>
              <div className={styles.breadcrumbHolderShadow}>
                <div className={styles.breadcrumbText}>
                  <Breadcrumb
                    breadCrumbArray={breadCrumbArray}
                    changePage={onPageChange}
                  />
                </div>
              </div>
            </div>
          )}
          {/* <div className={styles.contentHolder}>
            <img
              className={styles.image}
              style={{
                width: fitOn === "w" ? "100%" : "none",
                height: fitOn === "h" ? "100%" : "none"
              }}
              src="https://picsum.photos/id/320/600/800"
              onLoad={imageOnLoad}
              alt=""
            />
            <button onClick={changeAudio}>Change Audio</button>
            <Video />
          </div> */}
          {showPage ? (
            <div className={styles.stageContentHolder}>{showPage}</div>
          ) : (
            <Page
              openPage={launchFirstPage}
              playAudio={changeAudio}
              onClickAndChange={clickAndChange}
            />
          )}
          {showPage && (
            <div className={styles.navigationHolder}>
              <Navigation
                audio={audioToPlay}
                setToggleToC={() => setToggleToC(!toggleToC)}
                treeData={treeData}
                loadPage={launchPage}
                tocLoadKey={tocLoadKey}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Main;
