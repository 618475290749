import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Icon, Menu, Segment, Card, Image, Button } from "semantic-ui-react";
import styles from "./css/page_1_1.module.css";

const text = [
  "This measures the height of the letter.",
  "This measures the height of the lower case letter.",
  "This measures the height of the tail of the lower case letter.",
  "This measures the stem of the lower case letter.",
  "This measures the height of the diacritical marks.",
  "This measures the space between 2 letters.",
  "This measures the minimum, space between the base lines.",
  "This measures the minimum, space between the base lines.",
  "This measures the minimum, space between the base lines.",
  "This measures the space between the 2 words.",
  "This is the width of the stem."
];
const buttonText = [
  "Lettering height",
  "Height of lower-case letters",
  "Tail of lower-case letters",
  "Stem of lower-case letters",
  "Area of diacritical marks (lower-case letters)",
  "Spacing between characters",
  "Type 1: Minimum spacing between baselines",
  "Type 2: Minimum spacing between baselines",
  "Type 3: Minimum spacing between baselines",
  "Spacing between words",
  "Line width"
];

function Page_1_1({ playAudio, onClickAndChange }) {
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(null);
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_1/audio_1.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.instruction}>
          <Instruction>
            Click on the buttons on the left to know about it.
          </Instruction>
        </div>
        <div className={styles.note}>
          Note: The ratio of thickness and height of the stem should follow BIS
          standard. In most case the height to width ratio will be 6:5 but it
          will vary.
        </div>
        <div className={styles.innerStage}>
          <div className={styles.innerStageLeft}>
            {buttonText.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    step === index
                      ? [styles.buttons, styles.buttonsSelected].join(" ")
                      : styles.buttons
                  }
                  onClick={() => setStep(index)}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div className={styles.innerStageRight}>
            {step !== null && (
              <div className={styles.stepImage}>
                <Image
                  src={"/assets/images/page_1_1/step_" + (step + 1) + ".png"}
                />
              </div>
            )}
            <div>
              <Image src={"/assets/images/page_1_1/lettering.png"} />
              <div className={styles.explanation}>{text[step]}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_1_1;
