import { CHANGE_TEXT_COLOR } from "../actions/RCShellAction";

const RCShellReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_TEXT_COLOR:
      const tmpState = { ...state.shellData };
      const tmpColors = { ...tmpState.Colors };
      const modifiedColor = { ...tmpColors, text: action.color };
      const modifiedState = { ...tmpState, colors: { ...modifiedColor } };
      const newState = {
        ...state,
        shellData: { ...modifiedState }
      };

      return { ...newState };
    default:
      return { ...state };
  }
};

export default RCShellReducer;
