import React from "react";

import styles from "./button.module.css";

function Button({ children, ...rest }) {
  return (
    <div className={styles.button} {...rest}>
      {children}
    </div>
  );
}

export default Button;
