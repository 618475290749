import React, { useState, useEffect, useRef, forwardRef } from "react";
import ReactPlayer from "react-player";
import screenful from "screenfull";
import {
  IoIosPlay,
  IoIosPause,
  IoMdExpand,
  IoMdContract,
  IoMdVolumeHigh,
  IoIosVolumeMute,
  IoIosVolumeLow,
  IoIosFastforward
} from "react-icons/io";
import styles from "./css/video.module.css";

const format = seconds => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date
    .getUTCSeconds()
    .toString()
    .padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};
let count = 0;

function Video({
  src,
  showControls,
  autoPlay,
  onEnd,
  title,
  subTitle,
  externalControl,
  externalFullScreen
}) {
  //Video Player
  const [state, setState] = useState({
    pip: true,
    playing: autoPlay,
    controls: false,
    light: false,

    muted: false,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    volumeVideo: 1,
    lessFifty: false,
    loop: false,
    seeking: false,
    isFullscreen: false
  });
  const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");
  const [showVolume, setShowVolume] = useState(false);

  const playerRef = useRef(null);
  const controlsRef = useRef(null);
  const playerContainerRef = useRef(null);
  const seekBar = useRef(null);
  const volumeBar = useRef(null);

  const {
    playing,
    controls,
    light,

    muted,
    loop,
    playbackRate,
    pip,
    played,
    seeking,
    volumeVideo,
    isFullscreen,
    lessFifty
  } = state;

  useEffect(() => {
    //console.log(played);
  }, [played]);

  useEffect(() => {
    showVolume &&
      volumeBar.current.style.setProperty(
        "--seek-before-width",
        `${Math.floor(volumeBar.current.value)}%`
      );
  }, [showVolume]);

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleProgress = changeState => {
    if (showControls) {
      //console.log(count);
      if (count > 3) {
        controlsRef.current.style.visibility = "hidden";
        count = 0;
      }
      if (controlsRef.current.style.visibility == "visible") {
        count += 1;
      }
      if (!state.seeking) {
        setState({ ...state, ...changeState });
      }
    }
  };

  const handleSeekChange = newValue => {
    //console.log({ newValue });
    playerRef.current.seekTo(newValue / 100, "fraction");
    setState({ ...state, played: parseFloat(newValue / 100) });
  };

  const handleSeekMouseDown = e => {
    setState({ ...state, seeking: true, playing: false });
  };

  const handleSeekMouseUp = newValue => {
    setState({
      ...state,
      seeking: false,
      playing: true
    });
    playerRef.current.seekTo(newValue / 100, "fraction");
  };

  const handleDuration = duration => {
    setState({ ...state, duration });
  };

  const handleVolumeSeekDown = newValue => {
    setState({
      ...state,
      seeking: false,
      volumeVideo: parseFloat(newValue / 100)
    });
  };
  const handleVolumeChange = newValue => {
    setState({
      ...state,
      volumeVideo: parseFloat(newValue / 100),
      muted: Number(newValue) === 0 ? true : false,
      lessFifty: Number(newValue) <= 50 ? true : false
    });
  };

  const toggleFullScreen = () => {
    console.log("toggleFullScreen");
    //state.isFullscreen && onCloseFullScreen();
    setState({ ...state, isFullscreen: !isFullscreen });
    screenful.toggle(playerContainerRef.current);
  };

  const handleMouseMove = () => {
    /* console.log("mouse MOve"); */
    controlsRef.current.style.opacity = 1;
    count = 0;
  };

  const hanldeMouseLeave = () => {
    controlsRef.current.style.opacity = 0;
    count = 0;
  };

  const handleDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat == "normal" ? "remaining" : "normal"
    );
  };

  const handlePlaybackRate = rate => {
    setState({ ...state, playbackRate: rate });
  };

  const hanldeMute = () => {
    setState({ ...state, muted: !state.muted });
  };

  const speakerPress = () => {
    setShowVolume(!showVolume);
  };

  const changeRange = () => {
    //console.log(seekBar.current.value);
    seekBar.current.style.setProperty(
      "--seek-before-width",
      `${Math.floor(seekBar.current.value)}%`
    );
    //playerRef.current.currentTime = seekBar.current.value;
    handleSeekChange(seekBar.current.value);
  };
  useEffect(() => {
    if (!controls && externalControl) {
      setState({
        ...state,
        playing: externalControl === "play" ? true : false
      });
    }
  }, [externalControl]);

  useEffect(() => {
    if (!controls && externalFullScreen) {
      toggleFullScreen();
    }
  }, [externalFullScreen]);

  const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";

  const duration =
    playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
  const elapsedTime =
    timeDisplayFormat == "normal"
      ? format(currentTime)
      : `-${format(duration - currentTime)}`;

  const totalDuration = format(duration);
  const seconds = Math.floor(duration);
  if (currentTime != "00:00" && showControls) {
    //console.log(currentTime, played, duration);
    seekBar.current.value = Math.floor(played * 100);
    seekBar.current.style.setProperty(
      "--seek-before-width",
      `${Math.floor(played * 100)}%`
    );
    seekBar.current.max = 100;
  }
  return (
    <React.Fragment>
      <div className={styles.playerWrapper} ref={playerContainerRef}>
        <ReactPlayer
          ref={playerRef}
          width="100%"
          height="100%"
          url={src}
          pip={pip}
          playing={playing}
          controls={false}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volumeVideo}
          muted={muted}
          onProgress={handleProgress}
          config={{
            file: {
              attributes: {
                crossOrigin: "anonymous"
              }
            }
          }}
          onEnded={() => {
            onEnd();
            setState({ ...state, playing: false });
          }}
        />
        {!showControls ? (
          <div
            ref={controlsRef}
            className={styles.controlsWrapper}
            onMouseMove={handleMouseMove}
            onMouseLeave={hanldeMouseLeave}
          >
            <div className={styles.elementHolder}>
              {!isFullscreen ? (
                <div className={styles.buttonPanel} onClick={toggleFullScreen}>
                  <IoMdExpand />
                </div>
              ) : (
                <div className={styles.buttonPanel} onClick={toggleFullScreen}>
                  <IoMdContract />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            ref={controlsRef}
            className={styles.controlsWrapper}
            onMouseMove={handleMouseMove}
            onMouseLeave={hanldeMouseLeave}
          >
            {title && (
              <div className={styles.playerTitle}>
                <p>{title}</p>
              </div>
            )}
            {subTitle && (
              <div className={styles.playerSubTitle}>
                <p>{subTitle}</p>
              </div>
            )}
            <div className={styles.buttonCenterHolder}>
              <div
                className={styles.buttonFFRewindPanel}
                style={{
                  transform: "rotate(-180deg)",
                  boxShadow: "-3px -3px grey"
                }}
                onClick={handleRewind}
              >
                <IoIosFastforward />
              </div>
              <div
                className={styles.buttonPlayPausePanel}
                onClick={handlePlayPause}
              >
                {playing ? <IoIosPause /> : <IoIosPlay />}
              </div>
              <div
                className={styles.buttonFFRewindPanel}
                onClick={handleFastForward}
              >
                <IoIosFastforward />
              </div>
            </div>
            <div className={styles.elementHolder}>
              <input
                type="range"
                className={styles.seekBar}
                defaultValue="0"
                ref={seekBar}
                onChange={changeRange}
                onMouseDown={handleSeekMouseDown}
                onMouseUp={() => handleSeekMouseUp(seekBar.current.value)}
              />
              <div className={styles.timerPanel}>
                {elapsedTime}/{totalDuration}
              </div>
              <div className={styles.volumePanel}>
                {showVolume && (
                  <input
                    className={styles.volumeBar}
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    defaultValue={Math.round(volumeVideo * 100)}
                    ref={volumeBar}
                    onChange={() => {
                      handleVolumeChange(volumeBar.current.value);
                      volumeBar.current.style.setProperty(
                        "--seek-before-width",
                        `${Math.floor(volumeBar.current.value)}%`
                      );
                    }}
                  />
                )}
                <div className={styles.buttonPanel} onClick={speakerPress}>
                  {muted ? (
                    <IoIosVolumeMute />
                  ) : lessFifty ? (
                    <IoIosVolumeLow />
                  ) : (
                    <IoMdVolumeHigh />
                  )}
                </div>
              </div>
              {!isFullscreen ? (
                <div className={styles.buttonPanel} onClick={toggleFullScreen}>
                  <IoMdExpand />
                </div>
              ) : (
                <div className={styles.buttonPanel} onClick={toggleFullScreen}>
                  <IoMdContract />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Video;
