import React from "react";
import { RCShellProvider } from "./store/contexts/RCShellContext";
import Main from "./pages/main";

function App() {
  return (
    <RCShellProvider>
      <Main />
    </RCShellProvider>
  );
}

export default App;
