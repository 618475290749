import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import styles from "./css/page_1.module.css";

function Page_1({ playAudio, onClickAndChange }) {
  const [isLoading, setIsLoading] = useState(true);
  const images = [
    "./assets/images/page_1/box_1.jpg",
    "./assets/images/page_1/box_2.jpg",
    "./assets/images/page_1/box_3.jpg",
    "./assets/images/page_1/box_4.jpg",
    "./assets/images/page_1/box_5.jpg",
    "./assets/images/page_1/box_1_roll.jpg",
    "./assets/images/page_1/box_2_roll.jpg",
    "./assets/images/page_1/box_3_roll.jpg",
    "./assets/images/page_1/box_4_roll.jpg",
    "./assets/images/page_1/box_5_roll.jpg"
  ];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1/EG_U0_P0_T0_S00_00_00.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  const [image1, setImage1] = useState(0);
  const [image2, setImage2] = useState(0);
  const [image3, setImage3] = useState(0);
  const [image4, setImage4] = useState(0);
  const [image5, setImage5] = useState(0);
  const mouseOver = item => {
    switch (item) {
      case 1:
        setImage1(1);
        return;
      case 2:
        setImage2(1);
        return;
      case 3:
        setImage3(1);
        return;
      case 4:
        setImage4(1);
        return;
      case 5:
        setImage5(1);
        return;
    }
  };
  const mouseOut = item => {
    switch (item) {
      case 1:
        setImage1(0);
        return;
      case 2:
        setImage2(0);
        return;
      case 3:
        setImage3(0);
        return;
      case 4:
        setImage4(0);
        return;
      case 5:
        setImage5(0);
        return;
    }
  };
  return (
    <React.Fragment>
      {!isLoading ? (
        <div className={styles.container}>
          <Instruction>
            Click on the tile to reach corresponding topic.
          </Instruction>
          <div className={styles.holder}>
            <div className={styles.row}>
              <div
                className={styles.imageHolder1}
                onMouseOver={() => mouseOver(1)}
                onMouseOut={() => mouseOut(1)}
                onClick={() => onClickAndChange("1_0")}
              >
                {image1 ? <img src={images[5]} /> : <img src={images[0]} />}
              </div>
              <div
                className={styles.imageHolder2}
                onMouseOver={() => mouseOver(2)}
                onMouseOut={() => mouseOut(2)}
                onClick={() => onClickAndChange("1_1")}
              >
                {image2 ? <img src={images[6]} /> : <img src={images[1]} />}
              </div>
            </div>
            <div className={styles.row}>
              <div
                className={styles.imageHolder3}
                onMouseOver={() => mouseOver(3)}
                onMouseOut={() => mouseOut(3)}
                onClick={() => onClickAndChange("1_2")}
              >
                {image3 ? <img src={images[7]} /> : <img src={images[2]} />}
              </div>
              <div
                className={styles.imageHolder4}
                onMouseOver={() => mouseOver(4)}
                onMouseOut={() => mouseOut(4)}
                onClick={() => onClickAndChange("1_3")}
              >
                {image4 ? <img src={images[8]} /> : <img src={images[3]} />}
              </div>
              <div
                className={styles.imageHolder5}
                onMouseOver={() => mouseOver(5)}
                onMouseOut={() => mouseOut(5)}
                onClick={() => onClickAndChange("1_4")}
              >
                {image5 ? <img src={images[9]} /> : <img src={images[4]} />}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>{/* <ThreeDotsWave /> */}</div>
      )}
    </React.Fragment>
  );
}

export default Page_1;
