import { IoChevronForwardOutline } from "react-icons/io5";
import React, { useState, useEffect } from "react";
import styles from "./tree.module.css";

const Tree = ({ data = [], changePage }) => {
  const [dataTree, setDataTree] = useState([]);
  useEffect(() => {
    setDataTree([...data]);
  }, [data]);
  return (
    <ul className={styles.dTreeContainer}>
      {dataTree.map(tree => (
        <TreeNode node={tree} key={tree.key} changePage={changePage} />
      ))}
    </ul>
  );
};

const TreeNode = ({ node, changePage }) => {
  const [childVisible, setChildVisiblity] = useState(node.expand);
  const hasChild = node.children ? true : false;
  useEffect(() => {}, [childVisible]);
  return (
    <li className={styles.dTreeNode}>
      <div
        onClick={e => {
          setChildVisiblity(v => !v);
        }}
        className={styles.dTreeElementHolder}
      >
        {hasChild && (
          <div
            className={
              childVisible ? styles.dTreeTogglerActive : styles.dTreeToggler
            }
          >
            <IoChevronForwardOutline />
          </div>
        )}

        <div
          className={styles.dTreeHead}
          onClick={e => changePage(node.file, node.key)}
        >
          {node.label}
        </div>
      </div>

      {hasChild && childVisible && (
        <div>
          <ul className={styles.dTreeContainer}>
            <Tree data={node.children} changePage={changePage} />
          </ul>
        </div>
      )}
    </li>
  );
};

export default Tree;
