import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import Question from "../compoments/Question/Question";
import Button from "../compoments/Button/Button";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import Video from "./video";
import styles from "./css/page_2_3_1_1.module.css";

function Page_2_3_1_1({ playAudio }) {
  const [end, setEnd] = useState(true);
  const [playVideo, setPlayVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const images = ["./assets/images/page_2_3_1_1/image_1.jpg"];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_2_3_1_1/audio_0.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        console.log(img.src);
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  const drawSolution = () => {
    playAudio("");
    setPlayVideo(true);
  };

  return (
    <React.Fragment>
      {!isLoading ? (
        <div className={styles.container}>
          <div className={styles.questionHolder}>
            <Question>
              <b>Problem : </b>
              <br />
              Draw the orthographic views of the given isometric view using free
              hands:
              <br /> 1) Front view 2) Top view 3) Side view
            </Question>
            <br />
            <Instruction>
              Click on "Drawn Solution" button to see how to draw in sheet.
            </Instruction>
          </div>
          {!playVideo && (
            <React.Fragment>
              <div className={styles.imageHolder}>
                <img src={images[0]} />
              </div>
              <div className={styles.buttonHolder}>
                <Button onClick={drawSolution}>Drawn Solution</Button>
              </div>
            </React.Fragment>
          )}
          {playVideo && (
            <div className={styles.videoWrapper}>
              <div className={styles.videoHolder}>
                <Video
                  src={"assets/videos/page_2_3_1_1/video_0.mp4"}
                  showControls={true}
                  autoPlay={true}
                  onEnd={() => {
                    setEnd(true);
                  }}
                  title={"Free Hand Sketching"}
                  subTitle={"Isometric view to Orthographic view"}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <ThreeDotsWave />
        </div>
      )}
    </React.Fragment>
  );
}

export default Page_2_3_1_1;
