import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Icon, Menu, Segment, Card, Image } from "semantic-ui-react";
import styles from "./css/page_1_0.module.css";

function Page_1_0({ playAudio, onClickAndChange }) {
  const [isLoading, setIsLoading] = useState(true);
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_0/audio_0.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.tiles}>
          <Card.Group itemsPerRow={5}>
            <Card color="orange" onClick={() => onClickAndChange("1_0_0")}>
              <Image
                src={"/assets/images/page_1_0/drawing_board.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>
                  Drawing Board
                </Card.Header>
              </Card.Content>
            </Card>
            <Card color="orange" onClick={() => onClickAndChange("1_0_1")}>
              <Image
                src={"/assets/images/page_1_0/mini_drafter.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>
                  Mini drafter
                </Card.Header>
              </Card.Content>
            </Card>
            <Card color="orange" onClick={() => onClickAndChange("1_0_2")}>
              <Image
                src={"/assets/images/page_1_0/drawing_sheet.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>
                  Drawing sheet
                </Card.Header>
              </Card.Content>
            </Card>
            <Card color="orange" onClick={() => onClickAndChange("1_0_3")}>
              <Image
                src={"/assets/images/page_1_0/t_square.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>T-square</Card.Header>
              </Card.Content>
            </Card>
            <Card color="orange" onClick={() => onClickAndChange("1_0_4")}>
              <Image
                src={"/assets/images/page_1_0/box.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>
                  Instrument box
                </Card.Header>
              </Card.Content>
            </Card>
            <Card color="orange" onClick={() => onClickAndChange("1_0_5")}>
              <Image
                src={"/assets/images/page_1_0/drawing_pencil.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>
                  Drawing pencils
                </Card.Header>
              </Card.Content>
            </Card>
            <Card color="orange">
              <Image
                src={"/assets/images/page_1_0/french_curve.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>
                  French curve
                </Card.Header>
              </Card.Content>
            </Card>
            <Card color="orange">
              <Image
                src={"/assets/images/page_1_0/eraser.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>Eraser</Card.Header>
              </Card.Content>
            </Card>
            <Card color="orange">
              <Image
                src={"/assets/images/page_1_0/set_square.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>Set squares</Card.Header>
              </Card.Content>
            </Card>
            <Card color="orange">
              <Image
                src={"/assets/images/page_1_0/protractor.png"}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header className={styles.header}>Protractor</Card.Header>
              </Card.Content>
            </Card>
          </Card.Group>
        </div>
        <div className={styles.instruction}>
          <Instruction>Click on the above tools to know more.</Instruction>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_1_0;
