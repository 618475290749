import React, { useState, useEffect, useRef } from "react";
import styles from "./css/page.module.css";
import Video from "./video";

function Page({ openPage, playAudio }) {
  const [end, setEnd] = useState(false);
  const [launched, setLaunched] = useState(false);
  return (
    <div className={styles.container}>
      {!launched ? (
        <div className={styles.launchPanel}>
          <div
            className={styles.buttonPanel}
            onClick={() => {
              setLaunched(true);
            }}
          >
            Enter
          </div>
        </div>
      ) : !end ? (
        <div className={styles.videoHolder}>
          <Video
            src={"assets/videos/page/intro.mp4"}
            showControls={false}
            autoPlay={true}
            onEnd={() => {
              setEnd(true);
            }}
          />
        </div>
      ) : (
        <div className={styles.introHolder}>
          <div className={styles.introBanner}>
            <div className={styles.imageHolder}>
              <img src={"./assets/images/page/intro.jpeg"}></img>
            </div>
          </div>
          <div className={styles.introLauncher}>
            <div className={styles.title}>
              Engineering
              <br />
              Graphics
            </div>
            <div className={styles.subTitle}>by iDidos</div>
            <div className={styles.buttonPanel} onClick={openPage}>
              Launch Course
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Page;
