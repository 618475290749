import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Card, Image, Button, Icon } from "semantic-ui-react";
import Video from "./video";
import styles from "./css/page_1_0_5.module.css";

function Page_1_0_5({ playAudio }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isFlip1, setIsFlip1] = useState(true);
  const [isFlip2, setIsFlip2] = useState(true);
  const [isFlip3, setIsFlip3] = useState(true);
  const [end1, setEnd1] = useState(true);
  const [end2, setEnd2] = useState(true);
  const [end3, setEnd3] = useState(true);
  const [externalControl1, setExternalControl1] = useState("stop");
  const [externalControl2, setExternalControl2] = useState("stop");
  const [externalControl3, setExternalControl3] = useState("stop");

  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_0_5/audio_0.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  const cardClick1 = () => {
    setIsFlip1(!isFlip1);
  };
  const cardClick2 = () => {
    setIsFlip2(!isFlip2);
  };
  const cardClick3 = () => {
    setIsFlip3(!isFlip3);
  };
  const togglePlay1 = (e, titleProps) => {
    e.stopPropagation();
    if (end1) {
      setEnd1(false);
    } else {
      setEnd1(true);
    }
  };
  const togglePlay2 = (e, titleProps) => {
    e.stopPropagation();
    if (end2) {
      setEnd2(false);
    } else {
      setEnd2(true);
    }
  };
  const togglePlay3 = (e, titleProps) => {
    e.stopPropagation();
    if (end3) {
      setEnd3(false);
    } else {
      setEnd3(true);
    }
  };
  useEffect(() => {
    if (!end1) {
      setExternalControl1("play");
    } else {
      setExternalControl1("stop");
    }
  }, [end1]);
  useEffect(() => {
    if (!end2) {
      setExternalControl2("play");
    } else {
      setExternalControl2("stop");
    }
  }, [end2]);
  useEffect(() => {
    if (!end3) {
      setExternalControl3("play");
    } else {
      setExternalControl3("stop");
    }
  }, [end3]);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.leftHolder}>
          <Card>
            <Image
              src="/assets/images/page_1_0_5/pencils.png"
              wrapped
              ui={false}
            />
            <Card.Content>
              <Card.Header>Drawing pencils</Card.Header>
              {/* <Card.Meta>
                <span className="date">Joined in 2015</span>
              </Card.Meta> */}
              <Card.Description>
                Drawing pencils are used to draw drawings. The drawing pencils
                are of different grades like: HB, 2H, H. The different grades
                are used to make the drawings appear accurate.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Instruction>
                Click on the items to know where to use these different grades
                of pencil.
              </Instruction>
            </Card.Content>
          </Card>
        </div>
        <div className={styles.rightHolder}>
          <div className={styles.scene}>
            <div
              className={
                !isFlip1 ? [styles.card, styles.flipped].join(" ") : styles.card
              }
              onClick={cardClick1}
            >
              <div className={styles.cardFaceFront}>
                <div className={styles.textContent}>
                  HB is a medium hard pencil mainly used for freehand drawing
                  and lettering. Please note, since HB lines could mark much
                  impressions on the paper try to use H pencil instead as it is
                  bit easier to erase.
                </div>
                <Image
                  src="/assets/images/page_1_0_5/p_1.png"
                  wrapped
                  ui={false}
                  className={styles.imageHolder}
                />
              </div>
              <div className={styles.cardFaceBack}>
                <Video
                  src={"assets/videos/page_1_0_5/pencil_1.mp4"}
                  showControls={false}
                  autoPlay={false}
                  onEnd={() => {
                    setEnd1(true);
                  }}
                  title={""}
                  subTitle={""}
                  externalControl={externalControl1}
                />
                <div className={styles.buttonHolder}>
                  <Button icon labelPosition="left" onClick={togglePlay1}>
                    <Icon name={end1 ? "play" : "stop"} />
                    {end1 ? "Play" : "Stop"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.scene}>
            <div
              className={
                !isFlip2 ? [styles.card, styles.flipped].join(" ") : styles.card
              }
              onClick={cardClick2}
            >
              <div className={styles.cardFaceFront}>
                <div className={styles.textContent}>
                  2H pencil is used to draw mainly constructing lines and
                  dimensioning lines. It is used with the guide of the drafter.
                </div>
                <Image
                  src="/assets/images/page_1_0_5/p_2.png"
                  wrapped
                  ui={false}
                  className={styles.imageHolder}
                />
              </div>
              <div className={styles.cardFaceBack}>
                <Video
                  src={"assets/videos/page_1_0_5/pencil_2.mp4"}
                  showControls={false}
                  autoPlay={false}
                  onEnd={() => {
                    setEnd2(true);
                  }}
                  title={""}
                  subTitle={""}
                  externalControl={externalControl2}
                />
                <div className={styles.buttonHolder}>
                  <Button icon labelPosition="left" onClick={togglePlay2}>
                    <Icon name={end2 ? "play" : "stop"} />
                    {end2 ? "Play" : "Stop"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.scene}>
            <div
              className={
                !isFlip3 ? [styles.card, styles.flipped].join(" ") : styles.card
              }
              onClick={cardClick3}
            >
              <div className={styles.cardFaceFront}>
                <div className={styles.textContent}>
                  H Pencil is used to draw thicker lines in constructing lines.
                  It is used with the guide of the drafter.
                </div>
                <Image
                  src="/assets/images/page_1_0_5/p_3.png"
                  wrapped
                  ui={false}
                  className={styles.imageHolder}
                />
              </div>
              <div className={styles.cardFaceBack}>
                <Video
                  src={"assets/videos/page_1_0_5/pencil_3.mp4"}
                  showControls={false}
                  autoPlay={false}
                  onEnd={() => {
                    setEnd3(true);
                  }}
                  title={""}
                  subTitle={""}
                  externalControl={externalControl3}
                />
                <div className={styles.buttonHolder}>
                  <Button icon labelPosition="left" onClick={togglePlay3}>
                    <Icon name={end3 ? "play" : "stop"} />
                    {end3 ? "Play" : "Stop"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_1_0_5;
