import React, { useState, useEffect, useRef } from "react";
import { IoListOutline } from "react-icons/io5";
import {
  IoIosSkipForward,
  IoIosSkipBackward,
  IoIosPlay,
  IoIosPause,
  IoMdExpand,
  IoMdContract,
  IoMdVolumeHigh,
  /* IoMdVolumeOff, */
  IoMdMore
} from "react-icons/io";

import styles from "./navigation.module.css";
import { useRCShellContext } from "../../store/contexts/RCShellContext";

function Navigation({ audio, setToggleToC, treeData, loadPage, tocLoadKey }) {
  const { data, dispatch } = useRCShellContext();
  const [volume, setVolume] = useState(1);
  const [inFullScreen, setInFullScreen] = useState(false);
  const [toggleAudioControl, setToggleAudioControl] = useState(false);
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [showVolume, setShowVolume] = useState(false);
  const [seqNav, setSeqNav] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [audioToPlay, setAudioToPlay] = useState(audio);
  const [tocKey, setTocKey] = useState(tocLoadKey);
  //const [audioPlayer, setAudioPlayer] = useState(useRef());
  //console.log(tocLoadKey);
  const audioPlayer = useRef();
  const seekBar = useRef();
  const animationRef = useRef();
  const volumeBar = useRef();

  useEffect(() => {
    //console.log("tocLoadKey=========" + tocLoadKey);

    tocLoadKey &&
      seqNav.length > 1 &&
      setCurrentPage(seqNav.findIndex(element => element === tocLoadKey) + 1);
  }, [tocLoadKey]);
  let tempArr = [];
  const expandTargetParents = ({ tree }) => {
    if (tree && tree.length > 0) {
      tree.map(node => {
        tempArr.push(node.key);
        if (node.children && node.children.length > 0) {
          expandTargetParents({ tree: node.children });
        }
      });
    }
    setSeqNav([...tempArr]);
  };
  useEffect(() => {
    //console.log("------------------" + tocLoadKey);
    expandTargetParents({ tree: treeData });
  }, []);
  useEffect(() => {
    //console.log(seqNav);
  }, [seqNav]);
  useEffect(() => {
    //console.log(currentPage);
    setTocKey(null);
    loadPage(seqNav[currentPage - 1], treeData);
  }, [currentPage]);

  useEffect(() => {
    audio && changeAudio(audio);
    audio === "" && changeAudio(null);
  }, [audio]);

  const changeAudio = audioPath => {
    audioPlayer.current.pause();
    setAudioIsPlaying(false);
    cancelAnimationFrame(animationRef.current);
    setAudioToPlay(audio);
    audioPlayer.current.src = audioPath;
  };

  /*   useEffect(() => {
    audioToPlay && calculateDuration();
  }, [audioToPlay]); */
  /*   useEffect(() => {
    refCurrent && console.log(refCurrent.current.duration);

    refCurrent && console.log(refCurrent.current);
  }, [refCurrent?.current]); */

  useEffect(() => {
    audioToPlay && calculateDuration();
  }, [
    audioPlayer?.current?.loadedmetadata,
    audioPlayer?.current?.readyState,
    audioPlayer?.current?.playing
  ]);
  const calculateDuration = () => {
    if (audioPlayer) {
      const seconds = Math.floor(audioPlayer.current.duration);
      setDuration(isNaN(seconds) ? 0 : seconds);
      seekBar.current.value = 0;
      seekBar.current.max = seconds;
    }
  };

  useEffect(() => {
    duration && playAudio();
  }, [duration]);

  const playAudio = () => {
    setAudioIsPlaying(true);
    audioPlayer.current.play();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const whilePlaying = () => {
    if (seekBar.current) {
      seekBar.current.value = audioPlayer.current.currentTime;
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  };

  const changePlayerCurrentTime = () => {
    seekBar.current.style.setProperty(
      "--seek-before-width",
      `${(seekBar.current.value / duration) * 100}%`
    );
    setCurrentTime(seekBar.current.value);
  };

  const onAudioEnd = () => {
    setAudioIsPlaying(false);
  };
  const onAudioPlayerLoadedData = () => {
    calculateDuration();
    setCurrentTime(0);
  };
  const calculateTime = secs => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes} : ${returnedSeconds}`;
  };

  const onDurationChange = e => {};
  const onPlaying = e => {
    calculateDuration();
  };
  const onPause = e => {
    //calculateDuration();
  };

  const toggleAudioPlayPause = () => {
    if (!audioIsPlaying) {
      setAudioIsPlaying(true);
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      setAudioIsPlaying(false);
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const changeRange = () => {
    if (audioPlayer && audioPlayer.current) {
      audioPlayer.current.currentTime = seekBar.current.value;
      changePlayerCurrentTime();
    }
  };

  /* 


  useEffect(() => {
    if (audioToPlay) {
      changeAudio(audioToPlay);
      playAudio();
      setAudioIsPlaying(true);
    }
  }, [audioToPlay]);

  useEffect(() => {
    //!audioIsPlaying && audioPlayer.current.pause();
  }, [audioIsPlaying]); */

  useEffect(() => {
    showVolume &&
      volumeBar.current.style.setProperty(
        "--seek-before-width",
        `${Math.floor(volumeBar.current.value)}%`
      );
  }, [showVolume]);

  const onVolumeChange = () => {
    setVolume(audioPlayer.current.volume);
  };
  const changeVolume = () => {
    if (audioPlayer && audioPlayer.current) {
      audioPlayer.current.volume = volumeBar.current.value / 100;
    }
  };

  const toggleFullscreen = () => {
    let elem = document.getElementById("stageContent");
    var fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement;
    if (!fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen().then(() => {
          setInFullScreen(true);
        });
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen().then(() => {
          setInFullScreen(true);
        });
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen().then(() => {
          setInFullScreen(true);
        });
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen().then(() => {
          setInFullScreen(true);
        });
      }
    } else {
      setInFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  function exitHandler() {
    var fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement;
    if (!fullscreenElement) {
      setInFullScreen(false);
    }
  }
  return (
    <React.Fragment>
      <audio
        ref={audioPlayer}
        src={audioToPlay}
        onLoadedData={onAudioPlayerLoadedData}
        onEnded={onAudioEnd}
        onVolumeChange={onVolumeChange}
        onDurationChange={onDurationChange}
        onPlaying={onPlaying}
        onPause={onPause}
        /* autoPlay */
      ></audio>
      <div className={styles.actionHolder}>
        <div className={styles.actionPanel}>
          <div className={styles.buttonPanel} onClick={setToggleToC}>
            <IoListOutline />
          </div>
          <div
            className={styles.buttonPanel}
            disabled={currentPage <= 1}
            onClick={() => {
              setAudioIsPlaying(false);
              audioPlayer.current.pause();
              setAudioToPlay(null);
              currentPage != 1 && setCurrentPage(currentPage - 1);
            }}
          >
            <IoIosSkipBackward />
          </div>
          <div
            className={styles.buttonPanel}
            onClick={toggleAudioPlayPause}
            disabled={audioToPlay ? false : true}
          >
            {audioIsPlaying ? <IoIosPause /> : <IoIosPlay />}
          </div>
          <div
            className={styles.buttonPanel}
            disabled={currentPage >= seqNav.length}
            onClick={() => {
              setAudioIsPlaying(false);
              audioPlayer.current.pause();
              setAudioToPlay(null);
              currentPage != seqNav.length && setCurrentPage(currentPage + 1);
            }}
          >
            <IoIosSkipForward />
          </div>
        </div>
        <div className={styles.actionPanel2}>
          {/* audioToPlay && (
            <p className={styles.indicator}>
              {calculateTime(currentTime)} / {calculateTime(duration)}            </p>
          ) */}
          {/* audioToPlay && <p className={styles.indicator}>|</p> */}
          {/* <p className={styles.indicator}>
            {currentPage} / {seqNav.length}
          </p> */}
          {audioToPlay && (
            <div className={styles.progressHolder}>
              <input
                type="range"
                className={styles.seekBar}
                defaultValue="0"
                ref={seekBar}
                onChange={changeRange}
              />
            </div>
          )}
        </div>
        <div className={styles.actionPanel}>
          {audioToPlay && (
            <div className={styles.buttonPanel}>
              <div
                onClick={e => {
                  setShowVolume(!showVolume);
                  setToggleAudioControl(!toggleAudioControl);
                }}
              >
                <IoMdVolumeHigh className={{ flex: "1,0,1" }} />
              </div>
              {toggleAudioControl && (
                <div className={styles.volumeSlider}>
                  <input
                    ref={volumeBar}
                    defaultValue={Math.round(volume * 100)}
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    style={{ transform: "rotate(-90deg)" }}
                    className={styles.volumeBar}
                    onChange={() => {
                      changeVolume();
                      volumeBar.current.style.setProperty(
                        "--seek-before-width",
                        `${Math.floor(volumeBar.current.value)}%`
                      );
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div className={styles.buttonPanel}>
            <IoMdMore />
          </div>
          {/* !inFullScreen ? (
            <div
              className={styles.buttonPanel}
              onClick={e => {
                toggleFullscreen();
              }}
            >
              <IoMdExpand />
            </div>
          ) : (
            <div
              className={styles.buttonPanel}
              onClick={e => {
                toggleFullscreen();
              }}
            >
              <IoMdContract />
            </div>
          ) */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Navigation;
