import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import Question from "../compoments/Question/Question";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import styles from "./css/page_2_3_1_0.module.css";

function Page_2_3_1_0({ playAudio }) {
  const [isLoading, setIsLoading] = useState(true);
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1/EG_U0_P0_T0_S00_00_00.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        console.log(img.src);
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {!isLoading ? (
        <div className={styles.container}>
          <Question>
            <b>Problem : </b>
            <br />
            <b>
              Draw the orthographic views of the given isometric view using free
              hands:
              <br /> 1) Front view 2) Top view 3) Side view
            </b>
          </Question>

          <Instruction>
            Click on "Drawn Solution" button to see how to draw in sheet.
          </Instruction>
          <div className={styles.holder}></div>
        </div>
      ) : (
        <div className={styles.container}>
          <ThreeDotsWave />
        </div>
      )}
    </React.Fragment>
  );
}

export default Page_2_3_1_0;
