import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Icon, Menu, Segment, Card, Image, Button } from "semantic-ui-react";
import styles from "./css/page_1_2.module.css";

const text = [
  "This measures the height of the letter.",
  "This measures the height of the lower case letter.",
  "This measures the height of the tail of the lower case letter.",
  "This measures the stem of the lower case letter.",
  "This measures the height of the diacritical marks.",
  "This measures the space between 2 letters.",
  "This measures the minimum, space between the base lines.",
  "This measures the minimum, space between the base lines.",
  "This measures the minimum, space between the base lines.",
  "This measures the space between the 2 words.",
  "This is the width of the stem."
];
const buttonText = [
  "Continuous thick line",
  "Continuous thin line",
  "Dashed thin line",
  "Chain thin line",
  "Chain thin line thick at ends"
];

function Page_1_2({ playAudio, onClickAndChange }) {
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(null);
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_2/audio_1.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.note}>
          Lines in the drawing should be legible and clean. The lines vary based
          on its thickness and pattern. Each line will have a different meaning.
        </div>
        <div className={styles.instruction}>
          <Instruction>
            Click on the buttons on the left to know more on each pattern.
          </Instruction>
        </div>
        <div className={styles.innerStage}>
          <div className={styles.innerStageLeft}>
            {buttonText.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    step === index
                      ? [styles.buttons, styles.buttonsSelected].join(" ")
                      : styles.buttons
                  }
                  onClick={() => setStep(index)}
                >
                  <div className={styles.butText}>{item}</div>
                  <Image
                    src={"/assets/images/page_1_2/but_" + (index + 1) + ".png"}
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.innerStageRight}>
            <div className={styles.title}>
              <u>{buttonText[step]}</u>
            </div>
            <div className={styles.explanation}>
              {step !== null &&
                (step === 0 ? (
                  <p>
                    Continuous thick line are the line that shows the visible
                    edges of the object.
                  </p>
                ) : step === 1 ? (
                  <p>
                    Continuous thin line are the non object line used for
                    showing the measurements, etc.,
                  </p>
                ) : step === 2 ? (
                  <p>
                    Dashed thin line are the line shows the hidden edges of the
                    object.
                  </p>
                ) : step === 3 ? (
                  <p>
                    Chain thin line are used to show the axis of the object.
                  </p>
                ) : (
                  <p>
                    These lines are the lines that represent the sectional plane
                    that cut the object.
                    <br />
                    <b>Note:</b> In section of solid, when the sectional plane
                    will be thick inside the solid while assuming the sectioned
                    area is removed, and removed portion edge will be drawn with
                    thin lines.
                  </p>
                ))}
            </div>
            <div className={styles.stepImage}>
              {step !== null &&
                (step === 0 ? (
                  <Image src={"/assets/images/page_1_2/line_1.gif"} />
                ) : step === 1 ? (
                  <Image src={"/assets/images/page_1_2/line_2.gif"} />
                ) : step === 2 ? (
                  <Image src={"/assets/images/page_1_2/line_3.gif"} />
                ) : step === 3 ? (
                  <Image src={"/assets/images/page_1_2/line_4.gif"} />
                ) : (
                  <Image src={"/assets/images/page_1_2/line_5.gif"} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_1_2;
