import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Card, Image, Button, Icon } from "semantic-ui-react";
import Video from "./video";
import styles from "./css/page_1_0_3.module.css";

function Page_1_0_3({ playAudio }) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [end, setEnd] = useState(true);
  const [open, setOpen] = useState(false);
  const [externalControl, setExternalControl] = useState("stop");
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_0_3/audio_0.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  const togglePlay = (e, titleProps) => {
    if (end) {
      setEnd(false);
    } else {
      setEnd(true);
    }
  };
  useEffect(() => {
    if (!end) {
      setExternalControl("play");
    } else {
      setExternalControl("stop");
    }
  }, [end]);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.leftHolder}>
          <Card>
            <Image
              src="/assets/images/page_1_0_3/t_square.png"
              wrapped
              ui={false}
            />
            <Card.Content>
              <Card.Header>T - Square</Card.Header>
              {/* <Card.Meta>
                <span className="date">Joined in 2015</span>
              </Card.Meta> */}
              <Card.Description>
                T Square is a tool made up of good quality wood with the shape
                of T. Nowadays T Square is available in plastic with engraved
                scale. This is mainly used to draw horizontal lines.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Instruction></Instruction>
            </Card.Content>
          </Card>
        </div>
        <div className={styles.rightHolder}>
          <div className={styles.description}>
            The Stock of the T Square will be placed joining to the working edge
            of the board and can be slide along the working edge to draw
            horizontal lines. T Square with the combination of Set Square used
            to draw inclined and vertical lines.
          </div>
          <Instruction>
            Click on the play button to see how to use T Square.
          </Instruction>
          <Video
            src={"assets/videos/page_1_0_3/t_square.mp4"}
            showControls={false}
            autoPlay={false}
            onEnd={() => {
              setEnd(true);
            }}
            title={""}
            subTitle={""}
            externalControl={externalControl}
          />
          <div className={styles.buttonHolder}>
            <Button icon labelPosition="left" onClick={togglePlay}>
              <Icon name={end ? "play" : "stop"} />
              {end ? "Play" : "Stop"}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_1_0_3;
