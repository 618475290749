import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import {
  Icon,
  Menu,
  Segment,
  Card,
  Image,
  Table,
  Button
} from "semantic-ui-react";
import styles from "./css/page_1_3.module.css";

function Page_1_0_0({ playAudio }) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeItem, setActiveItem] = useState("1");
  const [position, setPosition] = useState(0);
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_3/audio_0.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  const handleItemClick = name => setActiveItem(name);
  const nextCLick = () => {
    setPosition(position + 1);
  };
  const backCLick = () => {
    setPosition(position - 1);
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <Menu pointing className={styles.menuHolder}>
          <Menu.Item
            name="1"
            active={activeItem === "1"}
            onClick={() => {
              handleItemClick("1");
              playAudio("./assets/audios/page_1_3/audio_0.mp3");
            }}
          >
            About
          </Menu.Item>

          <Menu.Item
            name="2"
            active={activeItem === "2"}
            onClick={() => {
              handleItemClick("2");
              playAudio("./assets/audios/page_1_3/audio_1.mp3");
            }}
          >
            Types
          </Menu.Item>

          <Menu.Item
            name="3"
            active={activeItem === "3"}
            onClick={() => handleItemClick("3")}
          >
            Terminology
          </Menu.Item>

          <Menu.Item
            name="4"
            active={activeItem === "4"}
            onClick={() => {
              handleItemClick("4");
              setPosition(0);
            }}
          >
            Good Practice
          </Menu.Item>
        </Menu>
        <Segment className={styles.sectionHolder}>
          {activeItem === "1" && (
            <div className={styles.singlePara}>
              The drawings that are drawn on the paper can show the shape of the
              object drawn in different views. But apart from the shape it is
              important to know the size of the object like, length, breadth,
              angles etc., Marking this information on the drawings is known as
              dimension. It is nothing but the measurement of the object in
              different views. All dimensions should be given in millimeters,
              with the abbreviation mm. When it is not convenient to give
              dimensions in mm another method is used, only the dimension
              figures are written. But a note such as 'all dimensions are in
              millimeters' is inserted in a visible place near title block.
            </div>
          )}
          {activeItem === "2" && (
            <React.Fragment>
              <div className={styles.sectionTopHolder}>
                There are 2 types of dimensions, they are: Unidirectional
                dimension and aligned dimension.
              </div>
              <div className={styles.sectionBottomHolder}>
                <div className={styles.sectionBottomLeftHolder}>
                  <div className={styles.imageHolder}>
                    <div className={styles.imageStage}>
                      <Image src={"/assets/images/page_1_3/tab_2_1.png"} />
                    </div>
                  </div>
                  <div className={styles.title}>
                    <u>Aligned dimension:</u>
                  </div>
                  <ul>
                    <li>
                      The dimension lines are parallel to the object line.
                    </li>
                    <li>
                      The dimension value is placed above the line without
                      breaking the dimension line.
                    </li>
                  </ul>
                </div>
                <div className={styles.hDivider}></div>
                <div className={styles.sectionBottomRightHolder}>
                  <div className={styles.imageHolder}>
                    <div className={styles.imageStage}>
                      <Image src={"/assets/images/page_1_3/tab_2_2.png"} />
                    </div>
                  </div>
                  <div className={styles.title}>
                    <u>Unidirectional Dimension:</u>
                  </div>
                  <ul>
                    <li>
                      The dimension lines are parallel to the object line.
                    </li>
                    <li>
                      The dimension value will be always from left to right and
                      can beak the vertical and inclined lines
                    </li>
                  </ul>
                </div>
              </div>
            </React.Fragment>
          )}
          {activeItem === "3" && (
            <div className={styles.imageHolder}>
              <div className={styles.imageStage}>
                <Image src={"/assets/images/page_1_3/tab_3.png"} />
              </div>
            </div>
          )}
          {activeItem === "4" && (
            <React.Fragment>
              <div className={styles.tabHolder4}>
                <div className={styles.title4}>
                  <u>Good Practice</u>
                </div>
                <div className={styles.practice}>
                  By comparing the 2 images, you can see what not to do while
                  writing dimensions.
                </div>
                <div className={styles.imageHolderQ}>
                  <div className={styles.imageStageQ}>
                    {position === 0 ? (
                      <Image src={"/assets/images/page_1_3/tab_4_1.png"} />
                    ) : position === 1 ? (
                      <Image src={"/assets/images/page_1_3/tab_4_2.png"} />
                    ) : (
                      <Image src={"/assets/images/page_1_3/tab_4_3.png"} />
                    )}
                  </div>
                </div>
                {position === 0 ? (
                  <div>
                    <p>
                      <b>A :</b> The values are wrongly placed over the
                      dimension line.
                      <br />
                      <b>B :</b> The dimension line should not overlap with the
                      extension line.
                      <br />
                      <b>C :</b> All dimensions should be placed outside the
                      object.
                    </p>
                  </div>
                ) : position === 1 ? (
                  <div>
                    <p>
                      <b>A :</b> The arrow head should touch the extension line.
                      <br />
                      <b>B :</b> The dimension line should be all well aligned.
                      <br />
                      <b>C :</b> No need to give the dimension information
                      twice.
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      <b>A :</b> No need to give the dimensions of the invisible
                      lines.
                      <br />
                      <b>B :</b> Smaller circle have to be mark with radius and
                      larger one with diameter in proper position.
                      <br />
                      <b>C :</b> Dimension with center line have to be done only
                      for holes.
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.buttonHolder}>
                <Button
                  basic
                  color="orange"
                  disabled={position === 0}
                  size="tiny"
                  onClick={backCLick}
                >
                  <Icon name="left arrow" />
                  Back
                </Button>
                <Button
                  basic
                  color="orange"
                  size="tiny"
                  onClick={nextCLick}
                  disabled={position === 2}
                >
                  Next
                  <Icon name="right arrow" />
                </Button>
              </div>
            </React.Fragment>
          )}
        </Segment>
      </div>
    </React.Fragment>
  );
}

export default Page_1_0_0;
