import React from "react";
import { Breadcrumb as SemanticBreadcrumb } from "semantic-ui-react";

const getBC = (bc, changePage) => {
  let content = [];
  for (let i = 0; i < bc.length; i++) {
    if (i + 1 === bc.length) {
      content.push(
        <SemanticBreadcrumb.Section
          key={bc[i].key}
          active
          style={{
            color: "var(--rc-shell-breadcrumb-active)",
            fontWeight: 400
          }}
        >
          {bc[i].content}
        </SemanticBreadcrumb.Section>
      );
    } else {
      content.push(
        <React.Fragment key={bc[i].key}>
          <SemanticBreadcrumb.Section
            onClick={() => changePage(bc[i].file, bc[i].key)}
            link
            style={{ color: "var(--rc-shell-breadcrumb-link)" }}
          >
            {bc[i].content}
          </SemanticBreadcrumb.Section>
          <SemanticBreadcrumb.Divider
            icon="right angle"
            style={{ color: "var(--rc-shell-breadcrumb-divider)" }}
          />
        </React.Fragment>
      );
    }
  }
  return content;
};

const Breadcrumb = ({ breadCrumbArray, changePage }) => (
  <SemanticBreadcrumb>{getBC(breadCrumbArray, changePage)}</SemanticBreadcrumb>
);

export default Breadcrumb;
