import React, { createContext, useReducer, useContext } from "react";
import { DATA } from "../data/RCShellData";
import RCShellReducer from "../reducers/RCShellReducer";
import { changeTextColor } from "../actions/RCShellAction";

export const RCShellContext = createContext();
const initialState = DATA;

const RCShellProvider = props => {
  const [data, dispatch] = useReducer(RCShellReducer, initialState);
  const rcShellData = { data, dispatch };
  return <RCShellContext.Provider value={rcShellData} {...props} />;
};

const useRCShellContext = () => {
  return useContext(RCShellContext);
};

export { RCShellProvider, useRCShellContext, changeTextColor };
