import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Icon, Menu, Segment, Card, Image, Button } from "semantic-ui-react";
import Draggable from "react-draggable";
import Video from "./video";
import styles from "./css/page_1_4.module.css";

function Page_1_4({ playAudio, onClickAndChange }) {
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(null);
  const [end, setEnd] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [videoPath, setVideoPath] = useState(null);
  const [fixOn, setFixOn] = useState(0);
  const items = [
    { title: "Rectangle", path: "assets/videos/page_1_4/rectangle.mp4" },
    { title: "Square", path: "assets/videos/page_1_4/square.mp4" },
    { title: "Triangle", path: "assets/videos/page_1_4/triangle.mp4" },
    { title: "Pentagon", path: "assets/videos/page_1_4/pentagon.mp4" },
    { title: "Hexagon", path: "assets/videos/page_1_4/hexagon.mp4" },
    { title: "Circle", path: "assets/videos/page_1_4/circle.mp4" }
  ];
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_4/audio_1.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };
  const eventLogger = (e, data) => {
    if (data.lastX < 100) {
      setFixOn(50);
      setVideoPath(0);
    } else if (data.lastX < 200) {
      setFixOn(150);
      setVideoPath(1);
    } else if (data.lastX < 300) {
      setFixOn(250);
      setVideoPath(2);
    } else if (data.lastX < 400) {
      setFixOn(350);
      setVideoPath(3);
    } else if (data.lastX < 500) {
      setFixOn(450);
      setVideoPath(4);
    } else if (data.lastX < 600) {
      setFixOn(550);
      setVideoPath(5);
    }
  };
  const handleStart = () => {};
  const handleDrag = () => {};
  const handleStop = () => {
    console.log("handleStop");
  };
  return (
    <div className={styles.container}>
      <Instruction>
        Use the slider to see how different geometrical shapes can be
        constructed in simple ways
      </Instruction>
      <div className={styles.sliderHolder}>
        <div className={styles.baselineDesign}></div>
        <div className={styles.spotHolder}>
          <div className={styles.spots} style={{ left: 50 }}></div>
          <div className={styles.spots} style={{ left: 150 }}></div>
          <div className={styles.spots} style={{ left: 250 }}></div>
          <div className={styles.spots} style={{ left: 350 }}></div>
          <div className={styles.spots} style={{ left: 450 }}></div>
          <div className={styles.spots} style={{ left: 550 }}></div>
        </div>
        <Draggable
          axis="x"
          handle=".handle"
          defaultPosition={{ x: 0, y: 0 }}
          position={{ x: fixOn, y: 0 }}
          grid={[1, 1]}
          scale={1}
          onStart={handleStart}
          onDrag={handleDrag}
          onStop={eventLogger}
          bounds={{ left: 0, top: 10, right: 600, bottom: 10 }}
        >
          <div className="handle">
            <div className={styles.handle}>
              <div className={styles.box1}></div>
              <div className={styles.box2}></div>
            </div>
          </div>
        </Draggable>
        <div className={styles.baseline}></div>
      </div>
      {videoPath !== null && (
        <div>
          <b>
            <u>{items[videoPath].title}</u>
          </b>
        </div>
      )}

      <div className={styles.videoStage}>
        {videoPath !== null && (
          <div className={styles.videoWrapper}>
            <div className={styles.videoHolder}>
              <Video
                src={items[videoPath].path}
                showControls={true}
                autoPlay={true}
                onEnd={() => {
                  setEnd(true);
                }}
                title={""}
                subTitle={""}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Page_1_4;
