import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Card, Image, Button, Modal } from "semantic-ui-react";
import Video from "./video";
import styles from "./css/page_1_0_2.module.css";

function Page_1_0_2({ playAudio }) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [end, setEnd] = useState(true);
  const [open, setOpen] = useState(false);
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_0_2/audio_0.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.leftHolder}>
          <Card>
            <Image
              src="/assets/images/page_1_0_2/sheet.png"
              wrapped
              ui={false}
            />
            <Card.Content>
              <Card.Header>Drawing sheet</Card.Header>
              {/* <Card.Meta>
                <span className="date">Joined in 2015</span>
              </Card.Meta> */}
              <Card.Description></Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Instruction></Instruction>
            </Card.Content>
          </Card>
        </div>
        <div className={styles.rightHolder}>
          Drawing sheet is a white sheet with uniform thickness used to draw
          drawings. The drawing sheet is of various size.
          <div className={styles.contentActive}>
            <div className={styles.accImageHolder}>
              <Image
                src="/assets/images/page_1_0_2/table.png"
                wrapped
                ui={false}
              />
            </div>
          </div>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={
              <div className={styles.modalButtonHolder}>
                <Button>How to fold drawing paper(A3)?</Button>
              </div>
            }
          >
            <Modal.Header>How to fold drawing paper(A3)?</Modal.Header>
            <Modal.Content className={styles.modalContent}>
              <Modal.Description className={styles.modalDescription}>
                <Video
                  src={"assets/videos/page_1_0_2/paper_fold.mp4"}
                  showControls={false}
                  autoPlay={true}
                  onEnd={() => {
                    setEnd(true);
                  }}
                  title={""}
                  subTitle={""}
                />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpen(false)} primary>
                OK
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_1_0_2;
