import React, { useState, useEffect, useRef } from "react";
import Instruction from "../compoments/Instruction/Instruction";
import ThreeDotsWave from "../compoments/Loader/ThreeDotsWave/ThreeDotsWave";
import { Card, Image, Accordion, Icon } from "semantic-ui-react";
import Video from "./video";
import styles from "./css/page_1_0_1.module.css";

function Page_1_0_1({ playAudio }) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [end, setEnd] = useState(true);
  const [playVideo, setPlayVideo] = useState(false);
  const images = [];
  useEffect(() => {
    cacheImages(images);
  }, []);
  useEffect(() => {
    !isLoading && playAudio("./assets/audios/page_1_0_1/audio_0.mp3");
  }, [isLoading]);

  const cacheImages = async srcArray => {
    const promises = await srcArray.map(src => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.leftHolder}>
          <Card>
            <Image
              src="/assets/images/page_1_0_1/mini_drafter.png"
              wrapped
              ui={false}
            />
            <Card.Content>
              <Card.Header>Mini Drafter</Card.Header>
              {/* <Card.Meta>
                <span className="date">Joined in 2015</span>
              </Card.Meta> */}
              <Card.Description>
                Drafter is a tool which has the combined features of Set Square,
                T Square, Scale and Protractor. Using this we can easily draw
                horizontal, vertical and inclined parallel lines to any length.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Instruction>Click on tab to know more.</Instruction>
            </Card.Content>
          </Card>
        </div>
        <div className={styles.rightHolder}>
          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleClick}
            >
              <Icon name="dropdown" />
              Parts
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <div className={styles.contentActive}>
                <div className={styles.accImageHolder}>
                  <Image
                    src="/assets/images/page_1_0_1/parts.png"
                    wrapped
                    ui={false}
                    className={styles.imageHolder}
                  />
                </div>
                <div className={styles.accDescHolder}>
                  The drafter is made up of following parts:
                  <ul>
                    <li>Knob </li>
                    <li>Scales</li>
                    <li>Angular scale</li>
                    <li>Scale fixing knob</li>
                    <li>Parallel bar</li>
                  </ul>
                </div>
              </div>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={handleClick}
            >
              <Icon name="dropdown" />
              How to fix?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <div className={styles.contentActive}>
                <div className={styles.accVideoHolder}>
                  <Video
                    src={"assets/videos/page_1_0_1/drafter_how_to_use.mp4"}
                    showControls={false}
                    autoPlay={true}
                    onEnd={() => {
                      setEnd(true);
                    }}
                    title={""}
                    subTitle={""}
                  />
                </div>
                <div className={styles.accDescHolder}>
                  <ul>
                    <li>Place drawing board on a table, </li>
                    <li>Place drawing paper on the drawing table, </li>
                    <li>
                      Fix the clamp along the vertical edge of the board,{" "}
                    </li>
                    <li>
                      Set the 0° of the protractor align to the reference line,
                    </li>
                    <li>
                      Make sure the scales can be reach to the complete surface
                      of the drawing board.
                    </li>
                  </ul>
                </div>
              </div>
            </Accordion.Content>
          </Accordion>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_1_0_1;
