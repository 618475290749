import React, { useState, useEffect, useRef } from "react";
import Title from "../compoments/Title/Title";
import Instruction from "../compoments/Instruction/Instruction";
import styles from "./css/page_0.module.css";

function Page_0({ playAudio }) {
  useEffect(() => {
    playAudio("./assets/audios/page_0/intro.mp3");
  }, []);
  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.holder}>
          <Title>Engineering Graphics</Title>
          <div className={styles.content}>
            is the graphical communication language of the engineers. The ideas
            and facts can be communicated exactly without ambiguity using
            engineering drawing. It is proved to be accurate and detailed
            compared to the verbal and written communication. Hence engineering
            drawing is highly important for all engineers.
          </div>
          <Instruction>
            Use left side "Course" menu to proceed to the different topics or
            click on "Next" button to proceed.
          </Instruction>
        </div>
        <div className={styles.imageHolder}>
          <img src={"./assets/images/page_0/scale.png"} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Page_0;
